import { makeStyles } from '@material-ui/core/styles';
import { pxToRem } from '../../../../styles/themes/vad/constants';
import isEmpty from 'lodash/isEmpty';
const useStyles = makeStyles((theme) => ({

  pillarCarousel: {
    '& .pillarImageWrapper': {
      cursor: 'pointer',
      position: 'relative',
      overflow: 'hidden',
      backgroundColor: 'rgba(0, 0, 0, 0.7)',
      '&.multiFilterWrapper': {
        // cursor: 'auto',
        cursor: 'pointer',
      },
      [theme.breakpoints.up('md')]: {
        minHeight: '416px',
      },
      [theme.breakpoints.up('lg')]: {
        height: pxToRem(560),
      },

      '& .multi-filtered-super-img': {
        '&.category-selected-super-image': {
          opacity: 0.6,
          '&:hover': {
            opacity: 1,
          },
        },
        '&.category-selected-super-hover-image': {
          opacity: 1,
        },
        position: 'absolute',
        height: '100%',
        width: '100%',
        top: 0,
        left: '0',
        zIndex: 3,
        opacity: 0,
        transition: 'all .3s ease',

        [theme.breakpoints.down('md')]: {
          opacity: 1,
        },

        '& svg': {
          width: '100%',
          height: '100%',
          minHeight: '100%',
          minWidth: '100%',
        },
      },

      '&:hover .multi-filtered-super-img': {
        opacity: 1,
      },

      [theme.breakpoints.down('md')]: {
        height: pxToRem(360),
      },

      '& .pillarCarouselVideo': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: 1,
      },

      '& .pillarContent': {
        pointerEvents: 'none',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        // height: '100%',
        // width: '90%',
        width: 'calc(100% - 20px)',
        position: 'absolute',
        color: '#FFFF',
        ...theme.mixins.paddingLeft(24),
        zIndex: '10',
        transition: 'all .5s ease',

        [theme.breakpoints.up('lg')]: {
          top: '100%',
          justifyContent: 'unset',
          // transform: 'translateY(-16%)',
          paddingBottom: '40px',
          ...theme.mixins.paddingLeft(40),
          ...theme.mixins.paddingLeft(20),
        },
        [theme.breakpoints.down('md')]: {
          top: '100%',
          justifyContent: 'unset',
          transform: 'translateY(calc(-100% - 20px))',
          width: '95%',

        },
        '& .pillarTitle > div': {
          display: 'unset',
        },
        '& .pillarTitle': {
          // fontSize: '32px',
          fontWeight: 700,
          // lineHeight: '40px',
          '& h2': {
            // wordBreak: 'break-all',
            display: 'block',
            overflow: 'hidden',
          },

          [theme.breakpoints.up('lg')]: {
            transform: 'translateY(calc(-100% - 20px))',
            transition: 'all .5s ease',
            marginBottom: pxToRem(16),
          },

          [theme.breakpoints.down('md')]: {
            fontSize: '22px',
            lineHeight: '32px',
            marginBottom: pxToRem(12),
          },
        },

        '& .pillarDescription': {
          // fontSize: '22px',
          fontWeight: 400,
          // lineHeight: '32px',
          color: '#ffffff',
          opacity: 0,
          transition: 'all .5s ease',
          [theme.breakpoints.down('md')]: {
            // fontSize: '16px',
            fontWeight: 400,
            // lineHeight: '24px',
            opacity: 1,
          },
        },
      },
      // '&:hover .pillarContent': {
      //   [theme.breakpoints.up('lg')]: {
      //     transform: 'translateY(-33%)',
      //     '& .pillarDescription': {
      //       opacity: 1,
      //     },
      //   },
      // },
      '&:hover .pillarContent': {
        [theme.breakpoints.up('lg')]: {
          // transform: 'translateY(-37%)',
          transform: 'translateY(-100%)',
          '& .pillarTitle': {
            transform: 'translateY(0)',
          },
          '& .pillarDescription': {
            opacity: 1,
          },
        },
        [theme.breakpoints.up('xl')]: {
          // transform: 'translateY(-33%)',
          '& .pillarDescription': {
            opacity: 1,
          },
        },
      },

      '& .multi-filter-btn-hovered': {
        position: 'absolute',
        top: '24px',
        right: '24px',
        zIndex: 200,
        padding: '8px',
        height: '40px',
        width: '40px',
        backgroundColor: '#FF8707',
        color: '#fff',
        transition: 'all 0.3s ease',
        overflow: 'hidden',
        fontSize: '16px',
        lineHeight: '24px',
        fontWeight: 700,
        '&.multi-filter-btn-selected': {
          background: '#FFF',
          color: '#000',
          '& .filterBtnAddCategory, & .filterBtnRemoveCategory': {
            display: 'none',
          },
          '& .filterBtnCheckedCategory': {
            display: 'flex',
            alignItems: 'center',
          },
        },

        '&.mobile-multi-filter-btn': {
          width: '128px',
          borderRadius: '100px',
          padding: '8px 24px',

          '& .filterBtnAddCategory': {
            display: 'flex',
            '& .filterBtnText': {
              opacity: 1,
              visibility: 'visible',
            },
          },
        },

        '&.mobile-multi-filter-btn-selected': {
          height: '40px',
          width: '40px',
          padding: '8px',
          background: '#FFF',
          '& .MuiIconButton-label': {
            '& .filterBtnCheckedCategory': {
              display: 'flex',
            },
            '& .filterBtnAddCategory': {
              display: 'none',
            },
          },
        },

        '&.multi-filter-btn-hovered-selected': {
          background: '#FFF',
          color: '#000',
          '& .filterBtnAddCategory, & .filterBtnCheckedCategory': {
            display: 'none',
          },
          '& .filterBtnRemoveCategory': {
            display: 'flex',
            alignItems: 'center',
          },
        },


        '& .filterBtnText': {
          ...theme.mixins.marginLeft(8),
          width: 0,
          opacity: 0,
          visibility: 'hidden',

          [theme.breakpoints.up('lg')]: {
            ...theme.mixins.marginLeft(0),
          },
        },
        '& .MuiIconButton-label': {
          justifyContent: 'center',
          [theme.breakpoints.down('md')]: {
            justifyContent: 'flex-start',
          },
        },

        '& .filterBtnAddCategory': {
          display: 'flex',
          alignItems: 'center',
          // '& svg': {
          //   maxWidth: '24px',
          //   flex: '0 0 24px',
          // },
        },

        '& .filterBtnAddCategory, & .filterBtnRemoveCategory, & .filterBtnCheckedCategory': {
          '& svg': {
            maxWidth: '24px',
            flex: '0 0 24px',
          },
        },

        '& .filterBtnRemoveCategory, & .filterBtnCheckedCategory': {
          display: 'none',
        },
        '&:hover': {
          [theme.breakpoints.up('lg')]: {
            '&.multi-filter-btn-hovered-selected': {
              width: '146px',
            },
            width: '128px',
            borderRadius: '100px',
            padding: '8px 24px',
            '& .filterBtnText': {
              width: '100%',
              ...theme.mixins.marginLeft(8),
              opacity: 1,
              visibility: 'visible',
            },
          },
        },
      },


      '& .MuiButton-contained': {
        padding: '1px 10px',
      },
      '& .img-to-animate-wrapper': {
        '& picture': {
          opacity: 0.7,
        },
      },
      '& video': {
        objectFit: 'cover',
        width: '100%',
        height: '0',
        position: 'relative',
      },
      '&:before': {
        content: '""',
        position: 'absolute',
        top: '0',
        left: '0',
        height: '100%',
        width: '100%',
        zIndex: 1,
        background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.50) 0%, rgba(69.06, 69.06, 69.06, 0) 100%)',
      },

      '& .animateBox1, & .animateBox': {
        position: 'absolute',
        width: '100%',
        height: '100%',
        opacity: (props) => !isEmpty(props.fields?.supergraphicOpacity?.value) ? props.fields?.supergraphicOpacity?.value : '.6',
        zIndex: '5',
        transition: 'ease .7s all',
      },

      '& .animateBox1': {
        top: pxToRem(-281),
        left: pxToRem(-226),
        [theme.breakpoints.down('md')]: {
          top: pxToRem(-127.2),
          left: pxToRem(-32),
        },
      },
      '& .animateBox': {
        height: 'auto',
        bottom: pxToRem(-316),
        right: pxToRem(-170),
        [theme.breakpoints.down('md')]: {
          bottom: 0,
          right: '0',
        },
      },

      '&:hover .animateBox': {
        [theme.breakpoints.up('lg')]: {
          bottom: 0,
          right: '0',
        },
      },
      '&:hover .animateBox1': {
        [theme.breakpoints.up('lg')]: {
          top: pxToRem(-146),
          left: pxToRem(-100),
        },
      },

      '& .customSVG': {
        maxWidth: 'unset',
        maxHeight: 'unset',
      },

      '& picture': {
        paddingTop: '0',
      },
      '& :not(.img-to-animate-wrapper) img, & .img-to-animate-wrapper': {
        transition: 'all 1s ease',
      },
      '&.collage': {
        marginBottom: theme.spacing(4),
      },
    },

    '& .collage-description': {
      '& .pillar-shared-title': {
        fontWeight: 700,
        marginBottom: pxToRem(16),
        [theme.breakpoints.down('md')]: {
          marginBottom: pxToRem(12),
        },
      },
      '& .pillar-shared-description': {
        marginBottom: '0',
        [theme.breakpoints.down('md')]: {
          marginBottom: pxToRem(40),
        },
      },
    },

    '& .PillarCard ': {
      '& .isPillar-height': {
        paddingBottom: '8px',
        [theme.breakpoints.down('lg')]: {
          paddingBottom: '24px',
        },
      },
    },

    [theme.breakpoints.down('lg')]: {
      marginTop: pxToRem(48),
      marginBottom: pxToRem(48),
    },

    [theme.breakpoints.up('lg')]: {
      marginTop: pxToRem(100),
      marginBottom: pxToRem(100),

      '& .PillarCard ': {
        '& .slick-list': {
          overflow: 'visible',
          marginLeft: 0,
        },

        '& .slick-track': {
          paddingBottom: '0',
        },

        '& .slick-disabled': {
          display: 'none !important',
        },
        '& .slick-slide': {
          '&:hover': {
            '& :not(.img-to-animate-wrapper) img, & .img-to-animate-wrapper': {
              transform: 'unset !important',
            },
          },
        },
        '& .slick-next': {
          left: 'auto',
          right: '8px',
        },
        '& .slick-prev': {
          left: '8px',
          right: 'auto',
        },

        '& .slick-next > button, & .slick-prev  > button': {
          border: 'none',
          width: '56px',
          height: '56px',

          '&:hover': {
            backgroundColor: (props) => !isEmpty(props.fields?.arrowColor?.value) ? props.fields?.arrowColor?.value : '#FF8707',
          },
        },
      },

    },
    [theme.breakpoints.down('md')]: {
      marginTop: pxToRem(56),
      marginBottom: pxToRem(56),
      '& .PillarCard ': {
        '& .slick-slide': {
          ...theme.mixins.marginRight(24),
        },
      },
    },
  },
}));

export default useStyles;
