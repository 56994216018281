import React from 'react';
import { withNamespaces } from 'react-i18next';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import PropTypes from 'prop-types';
import SharedMultiCardContent from '../.././../components/organism/SharedMultiCardcontent/SharedMultiCardContent';
import withErrorBoundary from '../../../utils/HOCs/withErrorBoundary';
import { COMPONENT_NAME } from './locators';
import useStyles from './style';
import {
  getPageName,
  getComponentName,
} from '../../../utils/getPageComponentInfo';
import Box from '../.././../components/molecules/Box';
import { getUId } from '../../../../utils/utility';
const PillarsCarousel = props => {
  const uid = getUId(props)
  const classes = useStyles(props);
  const updatedProps = {
    ...props,
    ...{
      fields: {
        ...props.fields,
        ...{
          pageName: getPageName(props),
          componentName: getComponentName(props),
        },
      },
    },
  };

  return (
    <Box className={classes.pillarCarousel} uid={uid} data-locator={COMPONENT_NAME}>
      <SharedMultiCardContent {...updatedProps} data-locator={COMPONENT_NAME} />
    </Box>
  );
};

PillarsCarousel.propTypes = {
  carouselItems: PropTypes.array,
  title: PropTypes.object,
  description: PropTypes.object,
};

PillarsCarousel.defaultProps = {
  carouselItems: [],
  title: {},
  description: {},
};

export default withErrorBoundary(
  withSitecoreContext()(withNamespaces()(PillarsCarousel))
);
